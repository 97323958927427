import { userAuthenticated, userInitialized, loadRolesAndPermissions, loadUserSettings } from "./userDataSlice";
import { loadChatDashboardData, loadChats } from "./chatsSlice";

let subscriptions = [];

const userDataMiddleware = store => next => async action => {
    const { dispatch } = store;
    if (userAuthenticated.match(action)) {
        dispatch(userInitialized(false));

        const user = action.payload;
        if (user) {
            try {
                const userId = user.Id;
                // load user role and it's permissions
                await dispatch(loadRolesAndPermissions(userId)).unwrap();

                // load user settings
                await dispatch(loadUserSettings(userId)).unwrap();

                // here user is initialized
                dispatch(userInitialized(true));

                // load and subscribe to chat dashboard data
                subscriptions.push(await dispatch(loadChatDashboardData(userId)).unwrap());

                // load and subscribe to chats
                subscriptions.push(await dispatch(loadChats()).unwrap());
            }
            catch (error) {
                console.log(error)
                dispatch(userInitialized(false));
            }
        } else {
            subscriptions.forEach(cancellable => cancellable());
            subscriptions = []
        }
    }

    next(action);
}
 
export default userDataMiddleware;