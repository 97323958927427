import { configureStore } from '@reduxjs/toolkit';
import chatsReducer from "./chatsSlice";
import userDataReducer from "./userDataSlice";
import userDataMiddleware from "./userDataMiddleware"

// devtools and thunk middlewares are added automatically 
const store = configureStore({ 
    reducer: { 
        chats: chatsReducer, 
        userData: userDataReducer 
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // ignore timestamps
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['chats/dashboardChatDataLoaded', 'chats/chatsAdded', 'chats/loadChatDashboardData/fulfilled', 'chats/loadChats/fulfilled'],
                // Ignore these field paths in all actions
                ignoredActionPaths: [],
                // Ignore these paths in the state
                ignoredPaths: ['chats.dashboardChatData', 'chats.entities'],
            },
        }).concat([ userDataMiddleware ]), 
});

export default store