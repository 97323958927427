import React from "react"
import { Provider } from "react-redux"
import store from "./src/store"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Helmet } from "react-helmet"
import GatsbyConfig from "./gatsby-config";

const theme = createTheme({ 
  "typography": {
    "fontFamily": ['Raleway']
  }});

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    return <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{GatsbyConfig.siteMetadata.title}</title>
                  </Helmet>
                  {element}
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Provider>
}