import firebase from "firebase";
import { firestore } from "./index";
import gatsbyConfig from "../../gatsby-config";
import { Alarm } from "./models";

function getMultiUserDocuments(collectionName: string, schemaVersion: number, userId: string): firebase.firestore.Query {
    const collection = firestore.collection(getCollectionName(collectionName));
    if (schemaVersion < 8) {
      return collection.where("UserId", "==", userId);
    }
    return collection.where(`ReaderUserIds`, "array-contains", userId);
}

function getCollectionName(name: string): string {
    return `configurations/${gatsbyConfig.siteMetadata.firestore_configuration}/${name}`
}

function getCollection(collectionName: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData>  {
    return firestore.collection(getCollectionName(collectionName));
}

function toAlarmModel(document: firebase.firestore.DocumentSnapshot) : Alarm {
    const data = document.data();
    const reaction = data.Reaction ?? { Type: data.ReactionType, DateTime: data.ReactionDateTime };
    return { Id: document.id, ...data, Reaction: reaction }
}
  
export { getMultiUserDocuments, getCollectionName, getCollection, toAlarmModel };