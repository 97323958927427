require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

const path = require("path");
// Get paths of Gatsby's required rules, which as of writing is located at:
// https://github.com/gatsbyjs/gatsby/tree/fbfe3f63dec23d279a27b54b4057dd611dce74bb/packages/
// gatsby/src/utils/eslint-rules
const gatsbyRequiredRules = path.join(
  process.cwd(),
  "node_modules",
  "gatsby",
  "dist",
  "utils",
  "eslint-rules"
);

module.exports = {
  // TODO: gitignore *.env files
  siteMetadata: {
    title: 'Dose Medbox - Dashboard',
    description: 'Dose Medbox Administration',
    be_url: 'https://dashboard.dosemedbox.com',
    client_id: '2',
    client_secret: 'N1TjJ0qlxtyWRJYMLBDN2aQIO2iEwgA4BGG10svo',
    firebase_config: {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.FIREBASE_DATABASE_URL,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER,
      appId: process.env.FIREBASE_APP_ID,
      measurementId: process.env.FIREBASE_MEASUREMENT_ID
    },
    firestore_configuration: process.env.FIRESTORE_CONFIGURATION,
    firebase_use_emulator: process.env.FIREBASE_USE_EMULATOR,
    firestore_emulator_host: process.env.FIRESTORE_EMULATOR_HOST,
    firestore_emulator_port: process.env.FIRESTORE_EMULATOR_PORT,
    firebase_auth_emulator_url: process.env.FIREBASE_AUTH_EMULATOR_URL,
    firebase_functions_host: process.env.FIREBASE_FUNCTIONS_EMULATOR_HOST,
    firebase_functions_port: process.env.FIREBASE_FUNCTIONS_EMULATOR_PORT,
    firebase_storage_host: process.env.FIREBASE_STORAGE_EMULATOR_HOST,
    firebase_storage_port: process.env.FIREBASE_STORAGE_EMULATOR_PORT,
    algoliaAppId: process.env.ALGOLIA_APP_ID,
    algoliaApiKey: process.env.ALGOLIA_API_KEY,
  },
  /* Your site config here */
  plugins: [
    `gatsby-plugin-postcss`,
    // it makes redirect from /* to index.js
    `gatsby-plugin-netlify`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: "gatsby-plugin-eslint",
      options: {
        // Gatsby required rules directory
        rulePaths: [gatsbyRequiredRules],
        // Default settings that may be ommitted or customized
        stages: ["develop"],
        extensions: ["js", "jsx", "ts", "tsx"],
        exclude: ["node_modules", "bower_components", ".cache", "public"],
        // Any additional eslint-webpack-plugin options below
        // ...
      },
    },
    {
      resolve: "@sentry/gatsby",
      options: {
        dsn: "https://3fce35239357436fb607af0a0ed940c2@o1050071.ingest.sentry.io/6031339",
        sampleRate: 0.7,
      },
    },
  ],
}