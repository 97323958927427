import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import gatsbyConfig from "../../gatsby-config";

const isBrowser = typeof window !== "undefined";

if (!firebase.apps.length) {
  firebase.initializeApp(gatsbyConfig.siteMetadata.firebase_config);
}

let auth;
let functions;
let storage;

if (isBrowser) {
  functions = firebase.app().functions('europe-west3');
  if (gatsbyConfig.siteMetadata.firebase_use_emulator == "true") {
    functions.useEmulator(gatsbyConfig.siteMetadata.firebase_functions_host, gatsbyConfig.siteMetadata.firebase_functions_port);
  }
}

if (isBrowser) {
  auth = firebase.auth();
  if (gatsbyConfig.siteMetadata.firebase_use_emulator == "true") {
    auth.useEmulator(gatsbyConfig.siteMetadata.firebase_auth_emulator_url, { disableWarnings: true });
  }
}

if (isBrowser) {
  storage = firebase.storage();
  if (gatsbyConfig.siteMetadata.firebase_use_emulator == "true") {
    storage.useEmulator(gatsbyConfig.siteMetadata.firebase_storage_host, gatsbyConfig.siteMetadata.firebase_storage_port)
  }
}

const firestore = firebase.firestore();
if (gatsbyConfig.siteMetadata.firebase_use_emulator == "true") {
  firestore.useEmulator(gatsbyConfig.siteMetadata.firestore_emulator_host, gatsbyConfig.siteMetadata.firestore_emulator_port);
}

export { storage, auth, firestore, functions };
export default firebase;