import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase, { auth } from "../firebase";
import { getCollection } from "../firebase/firestoreUtils";
import { MANAGE_USERS } from "../permissionConstants";

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {},
    reducers: {
      userAuthenticated: (state, action) => {
        state.user = action.payload
      },
      permissionsLoaded: (state, action) => {
        state.permissions = action.payload
      },
      roleLoaded: (state, action) => {
        state.role = action.payload
      },
      userSettingsLoaded: (state, action) => {
        state.userSettings = action.payload
      },
      userInitialized: (state, action) => {
        state.initialized = action.payload
      },
    },
    extraReducers(builder) {
      builder
        .addCase(logout.fulfilled, () => {
          return {};
        })
    }
});

export const logout = createAsyncThunk('userData/logout', async () => {
    await auth.signOut();
});

export const loginWithEmail = createAsyncThunk('userData/loginWithEmail', async (request) => {
  const { email, password } = request;
  await auth.signInWithEmailAndPassword(email, password);
});

export const loginWithGoogle = createAsyncThunk('userData/loginWithGoogle', async () => {
  await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
});

export const loadRolesAndPermissions = createAsyncThunk('userData/loadRolesAndPermissions', async (userId, { dispatch }) => {
  const doc = await getCollection("user_roles").doc(userId).get();
  const roleValue = doc?.data()?.Role;
  if (!roleValue) {
    return
  }

  dispatch(roleLoaded(roleValue));
  const permissionsDoc = await getCollection("roles_permissions").doc(roleValue).get();
  const permissions = permissionsDoc?.data();
  if (permissions && permissions?.Permissions != null) {
    const permissionIds = permissions.Permissions.map(item => item.id);
    dispatch(permissionsLoaded(permissionIds));
  }
});

export const loadUserSettings = createAsyncThunk('userData/loadUserSettings', async (userId, { dispatch }) => {
  const userSettingsDocs = await getCollection("user_settings").where("UserId", "==", userId).get();
  if (userSettingsDocs.docs.length > 0) {
      let userSettings = userSettingsDocs.docs[0].data();
      dispatch(userSettingsLoaded({ Id: userSettings.id, FirstName: userSettings.FirstName, LastName: userSettings.LastName }));
  }
});

export const getUser = state => state.userData.user;
export const getRole = state => state.userData.role;
export const getPermissions = state => state.userData.permissions;
export const getIsUserDataInitialized = state => state.userData.initialized;
export const getUserSettings = state => state.userData.userSettings;
export const getCanManageUsers = state => getPermissions(state).indexOf(MANAGE_USERS) != -1;

export default userDataSlice.reducer;

export const { userAuthenticated, permissionsLoaded, roleLoaded, userSettingsLoaded, userInitialized } = userDataSlice.actions;