import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { getCanManageUsers, logout } from './userDataSlice'
import firebase from "../firebase";
import { getCollection } from "../firebase/firestoreUtils"

const chatsAdapter = createEntityAdapter({
  selectId: (chat) => chat.Id,
  sortComparer: (a, b) => b.UserName.localeCompare(a.UserName)
});

const getInitialState = () => chatsAdapter.getInitialState({ dashboardChatData: null })

export const chatsSlice = createSlice({
    name: 'chats',
    initialState: getInitialState(),
    reducers: {
      chatsAdded: chatsAdapter.addMany,
      chatUpdated: chatsAdapter.updateOne,
      dashboardChatDataLoaded: (state, action) => {
        state.dashboardChatData = action.payload;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(logout.fulfilled, () => {
          return getInitialState();
        })
    }
});

export const { selectAll: getChats, selectById: getChatById } = chatsAdapter.getSelectors(state => state.chats)
export const getDashboardChatData = state => state.chats.dashboardChatData;
export const getChatByUserId = createSelector(
  [getChats, (state, userId) => userId],
  (chats, userId) => chats.find(chat => chat.UserId === userId)
);

export const createChat = createAsyncThunk('chats/create', async (userId, { getState }) => {
    const existing = getChatByUserId(getState(), userId);
    if (existing != null) {
        return existing.Id;
    }
    const userSettingsDocs = await getCollection("user_settings").where("UserId", "==", userId).get();
    if (userSettingsDocs.docs.length !== 1) {
        throw Error("User is not found!");
    }

    const userSettings = userSettingsDocs.docs[0].data();
    const name = userSettings.FirstName ? userSettings.FirstName + " " + (userSettings.LastName ?? "") : "Anonym";
    const chat = await getCollection("chats").add({
        UserId: userId,
        UserName: name,
        SchemaVersion: userSettings.SchemaVersion,
        LastReadAt: firebase.firestore.FieldValue.serverTimestamp(),
        IsAnonymous: false
    });
    return chat.id
});

export const loadChatDashboardData = createAsyncThunk('chats/loadChatDashboardData', async (userId, { getState, dispatch }) => {
    if (getCanManageUsers(getState())) {
      const chatDataDoc = getCollection("dashboard_chat_data").doc(userId);
      const chatData = await chatDataDoc.get();
      if (!chatData.exists) {
          await chatData.ref.set({ LastReadMessages: [] });
      }
      return chatDataDoc.onSnapshot({ includeMetadataChanges: false }, (querySnapshot) => {
          dispatch(dashboardChatDataLoaded({ Id: userId, ...querySnapshot.data() }));
      });
    }
});

export const loadChats = createAsyncThunk('chats/loadChats', async ( _, { getState, dispatch }) => {
  if (getCanManageUsers(getState())) {
    return getCollection("chats")
        .onSnapshot({ includeMetadataChanges: false }, async (querySnapshot) => {
            const chatsToAdd = []
            for (const change of querySnapshot.docChanges()) {
                let chat = { Id: change.doc.id, ...change.doc.data()};
                if (change.type == "added") {
                    chatsToAdd.push(chat);
                }
                else if (change.type == "modified") {
                    dispatch(chatUpdated(chat));
                }
            }

            if (chatsToAdd.length > 0) {
              dispatch(chatsAdded(chatsToAdd));
            }
        });
  }
});

export default chatsSlice.reducer;

export const { chatsAdded, chatUpdated, dashboardChatDataLoaded } = chatsSlice.actions;